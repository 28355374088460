<template>
  <h1>Thắng test</h1>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped></style>
